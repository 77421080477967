import React from 'react';
import './MainImageSection.css';
import mainImage from '../assets/images/mainsection/banner.webp';

// Helper function to wrap each letter of a word with span
const wrapLetters = (text) => {
  return text.split('').map((letter, index) => (
    <span key={index} className="letter">{letter}</span>
  ));
};

const MainImageSection = ({ weddingDate, weddingName, weddingMessage }) => {
  const weddingNameSplit = weddingName.split(" ");
  
  return (
    <section id="mainImageSection" className="main-image-section" style={{ backgroundImage: `url(${mainImage})` }}>
      <div className="overlay">
        <div className="text-content">
          <p className="date-content">
            {new Date(weddingDate).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })}
          </p>
          
          <h1>{wrapLetters(weddingNameSplit[0])}</h1> {/* Wrap first name */}
          <h1 style={{ paddingLeft: '10px' }}>{wrapLetters(weddingNameSplit[1])} {wrapLetters(weddingNameSplit[2])}</h1> {/* Wrap last name */}
          
          <p>{weddingMessage}</p>
        </div>
      </div>
    </section>
  );
};

export default MainImageSection;
