import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainImageSection from './components/MainImageSection';
import HeroSection from './components/HeroSection';
import CoupleSection from './components/CoupleSection';
import StorySection from './components/StorySection';
import GallerySection from './components/GallerySection';
import RSVPSection from './components/RSVPSection';
import EventsSection from './components/EventsSection';
import ItinerarySection from './components/ItinerarySection';
import GiftTableSection from './components/GiftTableSection';
import Footer from './components/Footer';

// Imports de imágenes
import image1 from './assets/images/story/story1.jpeg';
import image2 from './assets/images/story/story2.jpeg';
import image3 from './assets/images/story/story3.jpeg';
import image4 from './assets/images/ourlove4.jpg';
import image5 from './assets/images/ourlove4.jpg';
import groomImage from './assets/images/couple/groom.jpeg'; 
import brideImage from './assets/images/couple/bride.jpeg'; 
import coupleImage from './assets/images/couple/couple.png';
import imageGallery1 from './assets/images/gallery/1.webp';
import imageGallery2 from './assets/images/gallery/2.webp';
import imageGallery3 from './assets/images/gallery/3.webp';
import imageGallery4 from './assets/images/gallery/4.webp';
import imageGallery5 from './assets/images/gallery/5.webp';
import imageGallery6 from './assets/images/gallery/6.webp';

import closingVideo from './assets/envelope/closing.mp4';
import openingVideo from './assets/envelope/opening.mp4';
import logo from './assets/envelope/Logo.png'; 
import logoGif from './assets/envelope/Logo.gif'; 
import backgroundMusic from './assets/envelope/Background-Music.mp3';

import { FaGlassCheers, FaMusic, FaUtensils, FaGlassMartiniAlt, FaBirthdayCake, FaRegClock, FaBookOpen } from 'react-icons/fa';
import { GiSpellBook, GiPartyPopper, GiBookmarklet } from 'react-icons/gi';
import { MdLocalBar, MdRestaurant, MdEventBusy } from 'react-icons/md';


import './App.css';

const pageTransition = {
  hidden: { opacity: 0, scale: 0.95 }, // Start with a slight scale-down
  visible: { 
    opacity: 1, 
    scale: 1, // Scale up to full size
    transition: { duration: 0.8 } // Control the speed of the animation
  },
  exit: { opacity: 0, scale: 0.95 } // Optional exit animation if you need
};

const SectionWrapper = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only animate once
    threshold: 0.1,    // When 10% of the element is visible
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="hidden"
      variants={pageTransition}
      style={{ overflow: 'hidden' }}
    >
      {children}
    </motion.div>
  );
};

function App() {
  return (
    <Router>
      <MainPage />
    </Router>
  );
}

const MainPage = () => {
  const location = useLocation(); // Get current location object (URL)
  
  // Parse the query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const [data, setData] = useState(null);

  const [isEnvelopeOpened, setIsEnvelopeOpened] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const openVideoRef = useRef(null);
  const audioRef = useRef(null);

  // Handle click on the closing video
  const handleEnvelopeClick = () => {
    setIsEnvelopeOpened(true); // Switch to opening video
    if (audioRef.current) {
      audioRef.current.volume = 0.3;
      audioRef.current.play(); // Reproduce la música cuando se hace clic
    }
  };

  // Once the opening video ends, show the page content
  const handleOpenVideoEnd = () => {
    setShowContent(true);
  };

  useEffect(() => {
    // Cuando el componente se monta, configurar el audio para que se mantenga en reproducción
    if (audioRef.current) {
      audioRef.current.volume = 0.3; // Ajusta el volumen si es necesario
    }
  }, []);

  const shouldShow = {
    mainImageSection: false,
    heroSection: true,
    eventsSection: true,
    itinerarySection: true,
    rsvpSection: true,
    giftTableSection: true,
    coupleSection: false,
    storySection: false,
    gallerySection: true,
  };

  const fetchGuestInfo = async () => {
    try {
      const guestResponse = await fetch(`https://zc1l59bgfi.execute-api.us-east-2.amazonaws.com/api/getGuestInfo?guest_code=${code}`);
      const guestData = await guestResponse.json();

      const response = {
        // Datos de la boda, eventos, etc.
        weddingName: "Bianca & Saúl",
        weddingMessage: "¡Nos Casamos!",
        weddingDate: "2025-02-08T17:00:00",

        coupleSectionTitle: "Sobre Nosotros",
        groomName: "Carlos Fleck",
        groomDescription: "Criado en un hogar lleno de amor con una familia llena de bendiciones. Desde muy joven fui amante de la naturaleza y de los animales.",
        groomDescription2: "Gracias por asistir.",
        brideName: "Jackeline Illescas",
        brideDescription: "Una persona extrovertida y amigable, que valora profundamente los lazos familiares y de amistad.",
        brideDescription2: "No podría haber pedido mejor compañía, para un momento tan especial en mi vida. Gracias por acompañarnos.",

        rsvpData: {
          groomParents: "Padre y Madre Novio",
          brideParents: "Padre y Madre Novia",
          invitationText: "Este dìa marcará uno de los momentos más especiales de nuestras vidas, y no podríamos imaginar vivirlo sin ustedes, quienes han sido parte importante de nuestra historia. En todo momento el cielo ha sido testigo de tantos sueños, celebramos no solo el amor que compartimos, sino también el cariño que sentimos por cada uno de ustedes.",
          invitationText2: "Su presencia en este día significa el mundo para nosotros, porque cada uno ha dejado una huella especial en nuestras vidas. Hoy les damos las gracias por estar a nuestro lado, por compartir nuestras alegrías y por ser ese círculo de apoyo que tanto valoramos.",
          invitationText3: "Este día no sería completo sin ustedes, nuestras personas más queridas. ¡Esperamos con el corazón lleno de gratitud que compartan con nosotros esta nueva etapa de nuestro viaje!",
          maxDate: "18 enero de 2025, 10:00 PM",
          brideAndGroom: "Carlos y Jaqueline",
          confirmationDate: "2024-10-26T22:00:00",
          invitationDetails: {
            guestName: guestData.guest_name || "No invitado",
            guestAdultCount: guestData.number_of_adults || 0,
            guestChildrenCount: guestData.number_of_childs || 0,
            confirmationStatus: guestData.confirmation_status,
            dressCode: "DRESS CODE FORMAL / LARGO "
          }
        },

        // Historia de amor
        storyTitle: "Love Story Timeline",
        story: [
          {
            date: 'Octubre 2018',
            title: 'First Date',
            description: 'Ya habíamos interactuado por  varias semanas, siempre hubo risas y pláticas interminables. En nuestra primera cita, el flechazo fue inmediato. Nos pusimos de acuerdo para almorzar juntos en la U y a platicar.',
            image: image1
          },
          {
            date: 'Marzo 2019',
            title: 'Ya somos novios',
            description: 'Luego de conocernos por varios meses, decidimos empezar una nueva etapa como más que amigos.',
            image: image2
          },
          {
            date: '20 de Noviembre de 2022',
            title: 'Marriage Proposal',
            description: 'Uno de los días más emocionantes y emotivos, el inicio de una nueva etapa en nuestra relación y la promesa de un amor para siempre.',
            image: image3
          }
        ],

        // Detalles del evento
        eventTitle: "Time & Place",
        events: [
          {
            title: 'Ceremonia',
            time: '5:30 pm',
            date: 'Jardín de Ceremonias',
            location: 'Hacienda Santo Tomás / Santa Lucia Milpas altas, km 36.5 Calle Principal.',
            link: 'https://ul.waze.com/ul?preview_venue_id=176488594.1765082545.13043778&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
            buttonText: 'Ubicación'
          },
          {
            title: 'Recepción',
            time: '06:30 pm',
            date: 'Jardin Principal',
            location: 'Hacienda Santo Tomás / Santa Lucia Milpas altas, km 36.5 Calle Principal.',
            link: 'https://ul.waze.com/ul?preview_venue_id=176488594.1765082545.13043778&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
            buttonText: 'Ubicación'
          }
        ],

        // GiftTableSection
        giftTitle: "Lluvia de sobres",
        giftDescription: "Lo que más valoramos es compartir este momento con ustedes. Si quieren acompañarnos también con un regalo especial, hemos optado por una lluvia de sobres, que será una bendición para nuestro inicio juntos puedes colocarlo en el buzón el día del evento.",
        giftAccount: "https://simangiftregistry.web.app/table/20004620",

        // ItinerarySection
        itineraryTitle: "Itinerary",
        itinerary: [
          { time: "05:30 PM", description: "Ceremonia", icon: <GiBookmarklet /> },
          { time: "06:15 PM", description: "Coctel de Bienvenida", icon: <FaGlassCheers /> },
          { time: "06:30 PM", description: "Primer Baile", icon: <FaMusic /> },
          { time: "07:00 PM", description: "Cena", icon: <FaUtensils /> },
          { time: "07:45 PM", description: "¡Inicia la Fiesta!", icon: <FaGlassMartiniAlt /> },
          { time: "09:30 PM", description: "Corte de Pastel", icon: <FaBirthdayCake /> },
          { time: "09:45 PM", description: "¡Sigue la Fiesta!", icon: <GiPartyPopper /> },
          { time: "11:30 PM", description: "Fin de la Recepción", icon: <FaRegClock /> },
        ]
        ,

        // Galería
        imagesGallery: [imageGallery1, imageGallery2, imageGallery3, imageGallery4, imageGallery5, imageGallery6]
      };

      setData(response);
    } catch (error) {
      console.error("Error fetching guest info:", error);
    }
  };

  useEffect(() => {
    fetchGuestInfo();
  }, [code]);

  if (!data) {
    return <img src={logo} alt="Boda Bianca y Saúl" className="logo-overlay" />;
  }

  return (
    <div className="App">
      <audio ref={audioRef} src={backgroundMusic} loop /> {/* Always present audio element */}
      {showContent ? (
        <>
          <Navbar shouldShow={shouldShow}/>

          {shouldShow.mainImageSection && (
            <SectionWrapper>
              <MainImageSection 
                weddingDate={data.weddingDate} 
                weddingName={data.weddingName} 
                weddingMessage={data.weddingMessage} 
              />
            </SectionWrapper>
          )}  

          {shouldShow.heroSection && (
            <SectionWrapper>
              <HeroSection weddingDate={data.weddingDate} />
            </SectionWrapper>
          )}

          {shouldShow.eventsSection && (
            <SectionWrapper>
              <EventsSection eventTitle={data.eventTitle} events={data.events} />
            </SectionWrapper>
          )}

          {shouldShow.itinerarySection && (     
            <SectionWrapper>
              <ItinerarySection itineraryTitle={data.itineraryTitle} itinerary={data.itinerary} />
            </SectionWrapper>
          )}

          {shouldShow.rsvpSection && (     
            <SectionWrapper>
              <RSVPSection guestCode={code} rsvpData={data.rsvpData} fetchGuestInfo={fetchGuestInfo} />
            </SectionWrapper>
          )}

          {shouldShow.giftTableSection && (
            <SectionWrapper>
              <GiftTableSection 
                giftTitle={data.giftTitle} 
                giftDescription={data.giftDescription} 
                giftAccount={data.giftAccount} 
              />
            </SectionWrapper>
          )}
          
          {shouldShow.coupleSection && (
            <SectionWrapper>
            <CoupleSection
              coupleSectionTitle={data.coupleSectionTitle}
              groomName={data.groomName}
              brideName={data.brideName}
              groomDescription={data.groomDescription}
              groomDescription2={data.groomDescription2}
              brideDescription={data.brideDescription}
              brideDescription2={data.brideDescription2}
              groomImage={groomImage}
              brideImage={brideImage}
              coupleImage={coupleImage}
            />
            </SectionWrapper>
          )}

          {shouldShow.storySection && (
            <SectionWrapper>
              <StorySection storyTitle={data.storyTitle} story={data.story} />
            </SectionWrapper>
          )}

          {shouldShow.gallerySection && (
            <SectionWrapper>
              <GallerySection images={data.imagesGallery} />
            </SectionWrapper>
          )}

          <Footer />

        </>
      ) : (
        <div className="video-container">
          <img src={logoGif} alt="Boda Bianca y Saúl" className="logo-overlay" /> {/* Reemplazamos la imagen por el GIF */}
          {!isEnvelopeOpened ? (
            <video
              src={closingVideo}
              autoPlay
              muted
              playsInline
              onClick={handleEnvelopeClick}
              className="video"
              style={{ cursor: 'pointer' }} // Indicates video is clickable
            />
          ) : (
            <video
              ref={openVideoRef}
              src={openingVideo}
              autoPlay
              muted
              playsInline
              onEnded={handleOpenVideoEnd}
              className="video"
            />
          )}
      </div>
      )}
    </div>
  );
};

export default App;
