import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import topLeftFlower from '../assets/images/top-left-flower.png';
import bottomRightFlower from '../assets/images/bottom-right-flower.png';
import topRightFlower from '../assets/images/top-right-flower.png';

const HeroSection = ({ weddingDate }) => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(weddingDate) - +new Date();
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, [weddingDate]);

  return (
    <section id="hero" className="hero-section">
      
      <div className="overlay">
        {/* Nombres y fecha */}
        <div className="date-container" style={{ zIndex: 6, position: 'absolute', top: '20%', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
          <h2 className="countdown-title">Bianca & Saúl</h2>
          <div className="title-decor">
              <div className="line"></div>
              <div className="circle"></div>
              <div className="line"></div>
          </div>
          <p className="wedding-date">
            {new Date(weddingDate).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })}
          </p>
        </div>

        {/* Contador */}
        <div>
          <div className="countdown">
            <div className="days" style={{ zIndex: 4 }}>
              <span>{String(timeLeft.days).padStart(2, '0')}</span>
              <p>días</p>
            </div>
            <div className="hours" style={{ zIndex: 3 }}>
              <span>{String(timeLeft.hours).padStart(2, '0')}</span>
              <p>horas</p>
            </div>
            <div className="minutes" style={{ zIndex: 2 }}>
              <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
              <p>minutos</p>
            </div>
            <div className="seconds" style={{ zIndex: 1 }}>
              <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
              <p>segundos</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
